// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
// require("remodal")



jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

// Обработчик события, срабатывающий при покидании страницы
// Проверяем, содержит ли текущий URL строку "/calculate"
if (!window.location.href.includes("/calculate")) {
    // Запрос к серверу для обновления состояния @cart.checkout на false
    fetch('/update_checkout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ checkout: false }),
    });
}

// Или, если вы хотите выполнить запрос, когда пользователь фактически перешел с этой страницы
// window.addEventListener('unload', function(event) {
//     fetch('/update_checkout', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ checkout: false }),
//     });
// });

// Запрос к серверу для обновления состояния @cart.checkout на true
// fetch('/update_checkout', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ checkout: true }),
// });

